import { getStatsigUserId } from '@experiences/ab-testing';
import { SeverityLevel } from '@microsoft/applicationinsights-web';
import { Statsig } from 'statsig-react';

import telemetryProvider from '../telemetry';

interface ITelemetryOptions {
    GAId?: string;
    platformName: string;
    signUpWithText: boolean;
}

export default class Telemetry {
    private static _telemetryOptions: ITelemetryOptions;

    static setTelemetryOptions(telemetryOptions: ITelemetryOptions) {
        Telemetry._telemetryOptions = telemetryOptions;
    }

    trackAuthorizationMethod(
        signup: boolean,
        method: 'basic' | 'social: microsoft' | 'social: google' | 'social: linkedin',
        severity: SeverityLevel = SeverityLevel.Information,
        message?: string,
    ) {
        const {
            GAId, platformName, signUpWithText,
        } = Telemetry._telemetryOptions;

        if (signup) {
            Statsig.logEvent('signup_submit', method, { severity: severity.toString() });
        }

        const options = {
            statsig_user_id: getStatsigUserId(),
            GAId,
            method,
            signUpWithText,
        };

        telemetryProvider.trackTrace(
            {
                message: message ?? (signup ? 'Pick Signup' : 'Login'),
                severityLevel: severity,
            },
            {
                ...options,
                ...(platformName != null ? { platformName } : {}),
            }
        );
    }
}
