import { abTestingUrl } from '@experiences/constants';
import {
    Features,
    getFeatureFlagConfig,
    getFeatureFlagValue,
} from '@experiences/feature-flags';
import type {
    AbTestingUserInfo,
    IExperiment,
    ILayerParameter,
    IParameterValue,
} from '@experiences/interfaces';
import {
    portalTelemetry,
    SeverityLevel,
} from '@experiences/telemetry';
import {
    getBasePath,
    post,
} from '@experiences/util';

import {
    AB_TESTING_GET_PARAMETER_VALUE_ERROR_CODE,
    AB_TESTING_GET_PARAMETERS_VALUES_ERROR_CODE,
    AB_TESTING_LOG_EVENT_ERROR_CODE,
    AB_TESTING_LOGGED_EVENTS_FEATURE_FLAG_PARSE_ERROR_CODE,
    AB_TESTING_LOGGING_GROUP,
} from './AbTestingLoggerUtil';

export async function getParameterValue<T>({
    experiment, parameter, userDetails, withExposureEnabled, accessToken,
}: {
    experiment: IExperiment;
    parameter: ILayerParameter;
    userDetails: AbTestingUserInfo;
    withExposureEnabled: boolean;
    accessToken: string;
}): Promise<T> {

    try {
        const EnableAbTestingSupport = getFeatureFlagValue(Features.EnableAbTestingSupport.name);
        if (!EnableAbTestingSupport) {
            return parameter.defaultValue;
        }

        const data = {
            ...userDetails,
            experimentKey: experiment.key,
            parameterKeys: [ parameter.key ],
            withExposureEnabled,
        };

        const response = await post<IParameterValue[]>(`${getBasePath()}${abTestingUrl}/getParametersValues`, {
            body: data,
            accessToken,
        });

        if (!response || response.length < 1 || response[0].key !== parameter.key) {
            return parameter.defaultValue;
        }
        return response[0].value;
    } catch (error: any) {
        if (error.config?.headers) {
            delete error.config.headers;
        }

        portalTelemetry.trackTrace({
            message: 'GetAbTestLayerParameterValue - Error getting A/B test layer parameter value',
            severityLevel: SeverityLevel.Major,
            properties: {
                name: 'GetAbTestLayerParameterValue',
                code: `[${AB_TESTING_GET_PARAMETER_VALUE_ERROR_CODE}]`,
                error,
            },
        });

        return parameter.defaultValue;
    }
}

export async function getParametersValues(experiment: IExperiment, parameters: ILayerParameter[],
    userDetails: AbTestingUserInfo, withExposureEnabled: boolean, accessToken: string
): Promise<IParameterValue[]> {
    const parametersDefaultValues = parameters.map(parameter => ({
        key: parameter.key,
        value: parameter.defaultValue,
    }));

    const EnableAbTestingSupport = getFeatureFlagValue(Features.EnableAbTestingSupport.name);
    if (!EnableAbTestingSupport) {
        return parametersDefaultValues;
    }

    try {
        const data = {
            ...userDetails,
            experimentKey: experiment.key,
            parameterKeys: parameters.map(parameter => parameter.key),
            withExposureEnabled,
        };

        const response = await post<IParameterValue[]>(`${getBasePath()}${abTestingUrl}/getParametersValues`, {
            body: data,
            accessToken,
        });

        return response ?? parametersDefaultValues;
    } catch (error) {
        portalTelemetry.trackTrace({
            message: 'GetAbTestLayerParametersValues - Error getting A/B test layer parameters values',
            severityLevel: SeverityLevel.Major,
            properties: {
                name: 'GetAbTestLayerParametersValues',
                code: `[${AB_TESTING_GET_PARAMETERS_VALUES_ERROR_CODE}]`,
                error,
            },
        });

        return parametersDefaultValues;
    }
}

export async function logEvent(eventName: string, eventValue: string | number | null,
    eventMetadata: Record<string, unknown> | null, userDetails: AbTestingUserInfo, accessToken: string
) {
    try {
        const EnableAbTestingSupport = getFeatureFlagValue(Features.EnableAbTestingSupport.name);
        if (!EnableAbTestingSupport) {
            return;
        }

        const data = {
            ...userDetails,
            eventName,
            eventValue,
            eventMetadata,
        };

        const enabledEvents = tryParseEnabledEventsForLogging(getFeatureFlagConfig(Features.EnableAbTestingLoggedEvents.name));
        if (enabledEvents.indexOf(eventName) < 0) {
            return;
        }

        await post<void>(`${getBasePath()}${abTestingUrl}/logEvent`, {
            body: data,
            accessToken,
        });
    } catch (error) {
        portalTelemetry.trackTrace({
            message: `${AB_TESTING_LOGGING_GROUP} - Error logging A/B test event`,
            severityLevel: SeverityLevel.Major,
            properties: {
                name: AB_TESTING_LOGGING_GROUP,
                code: `[${AB_TESTING_LOG_EVENT_ERROR_CODE}]`,
                error,
            },
        });
    }
}

function tryParseEnabledEventsForLogging(featureFlagValue: string): string[] {
    try {
        return JSON.parse(featureFlagValue);
    } catch (error) {
        portalTelemetry.trackTrace({
            message: 'ParseAbTestingLoggedEventsFeatureFlag - Error parsing EnableAbTestingLoggedEvents feature flag',
            severityLevel: SeverityLevel.Major,
            properties: {
                name: 'ParseAbTestingLoggedEventsFeatureFlag',
                code: `[${AB_TESTING_LOGGED_EVENTS_FEATURE_FLAG_PARSE_ERROR_CODE}]`,
                error,
                featureFlagValue,
            },
        });

        // Don't break user experience as A/B testing shouldn't be a critical dependency, but log error to be investigated ASAP
        return [];
    }
}
